.hubs_popup_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 30;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-color);
    overflow: auto;
    background-color: rgba(14,12,19,.5);

    &.hide {
        display: none !important;
    }

    &.show {
        display: block !important;
    }

    .multiselect__content-wrapper, .game_account_scrollbar {
        &::-webkit-scrollbar {
          width: 6px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background-color: var(--header-modal-game-account-bg);
        }
        &::-webkit-scrollbar-track {
          border-radius: 4px;
          background-color: var(--content-modal-game-account-bg);
        }
    }

    .multiselect__tags {
        background: var(--dropdown-modal-game-account-bg);
        border-radius: 0;
        border: none;
    }

    .multiselect__single {
        background: var(--dropdown-modal-game-account-bg);
        color: var(--text-color);
    }
    .multiselect__content-wrapper {
        background: var(--dropdown-modal-game-account-bg);
        color: var(--text-color);
        border: none;
        border-radius: 0;
    }
    .multiselect__option {
        background: var(--dropdown-modal-game-account-bg);
        color: var(--text-color);

        &--selected {
            background: var(--color-dark-alt);
        }

        &--highlight {
            background-color: var(--color-border);
        }
    }
    .option__title {
        display: flex;
        align-items: center;
        font-size: 13px !important;

        img {
            max-width: 20px;
            height: auto;
            margin-right: 10px;
        }
    }
    .multiselect__content-wrapper {
        max-height: 200px !important;
    }

    .multiselect__option--selected, .multiselect__option--selected.multiselect__option--highlight {
        background: #CECECE;
        color: #000000;
    }

    .hubs_popup_container{
        display: flex;
        flex-direction: column;
        min-width: 500px;
        zoom: 0.9;
        // filter: drop-shadow(1px 1px 8px rgba(0, 0, 0, 0.1));
        max-height: 100vh;

        .hubs_popup_header {
            background: var(--scrimmage-title-bg-color);
            border-radius: 5px 5px 0 0;
            clip-path: polygon(0 0, calc(100% - 20px) 0, 100% 20px, 100% 100%, 0 100%, 0 0);
            padding: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .hubs_popup_title {
                font-weight: bold;
                font-size: 25px;
                line-height: 35px;
            }

            .hubs_popup_esc {
                cursor: pointer;
            }

        }

        .hubs_popup_body {
            background: var(--community-scrimmage-section-bg-color);
            border-radius: 0 0 5px 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 40px;

            .hubs_popup_contents {
                display: flex;
                align-items: flex-start;
                justify-content: center;
                zoom: 1.15;
                width: 100%;

                .hubs_popup_content {
                    background: var(--scrimmage-title-bg-color);
                    border-radius: 4px;
                    clip-path: polygon(0 0, calc(100% - 20px) 0, 100% 20px, 100% 100%, 0 100%, 0 0);
                    padding: 18px;
                    width: 100%;

                    &.content_right {
                        margin-left: 30px;
                    }

                    .content_title {
                        font-weight: bold;
                        font-size: 16px;
                        line-height: 22px;
                        display: flex;
                        align-items: center;
                        padding-bottom: 10px;
                        border-bottom: 1px solid #525562;
                    }

                    .content_user_info {
                        padding: 10px 0px;
                        display: flex;
                        align-items: center;
                        border-bottom: 1px solid #586890;

                        & > img {
                            width: 40px;
                            height: 40px;
                            margin-right: 10px;
                            object-fit: cover;
                        }

                        .user_data {
                            display: flex;
                            flex-direction: column;

                            & > span {
                                font-weight: 600;
                                font-size: 26px;
                                line-height: 26px; 
                                max-width: 275px;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }

                            .smaller{
                                font-weight: normal;
                                font-size: 12px;
                                line-height: 15px;
                                color: #898B9C;
                                display: flex;
                                align-items: center;

                                & > img {
                                    width: 11px;
                                    height: 11px;
                                    margin-right: 5px;
                                }
                            }

                            &.right {
                                margin-left: auto;
                            }
                        }
                    }

                    .content_actions {
                        margin: 15px 0;
                        display: flex;
                        align-items: center;

                        input {
                            height: 36px;
                            width: 200px;
                            padding: 5px 10px;
                            font-weight: 600;
                            font-size: 12px;
                            line-height: 16px;
                            color: #ffffff;
                            opacity: .4;
                            margin-right: 15px;
                            background: #3D3F4A;
                            border: 1.5px solid #FFFFFF;
                            box-sizing: border-box;
                            border-radius: 2px;
                        }

                        .content_action_search {
                            display: flex;
                            flex-direction: column;
                            font-weight: bold;
                            font-size: 10px;
                            line-height: 14px;
                            display: flex;
                        }

                        &_buttons {
                            height: 36px;
                            padding: 5px 15px;
                            display: flex;
                            align-items: center;
                            text-align: center;
                            border-radius: 1.5px;
                            font-weight: 800;
                            font-size: 14px;
                            line-height: 19px;
                            cursor: pointer;

                            &.orange {
                                background: #EE733A;
                                color: #FFFFFF;
                            }
                        }
                    }

                    .content_cards {
                        display: flex;
                        flex-wrap: wrap;
                        width: 525px;
                        overflow-y: auto;
                        height: 400px;
                        align-content: flex-start;

                        &::-webkit-scrollbar {
                            width: 6px;
                        }
                        &::-webkit-scrollbar-thumb {
                        border-radius: 6px;
                        background-color: #8A8A8A;
                        }
                        &::-webkit-scrollbar-track {
                        border-radius: 6px;
                        background-color: #434554;
                        }
                    }

                    .content_form {
                        padding: 10px;

                        .form_group {
                            margin-bottom: 18px;

                            .form_title {
                                font-weight: bold;
                                font-size: 13px;
                                line-height: 13px;
                                display: flex;
                                align-items: center;
                                margin-bottom: 10px;
                            }

                            .form_input {
                                position: relative;

                                & > svg {
                                    position: absolute;
                                    top: 10px;
                                    left: 6px;
                                }

                                .icon_end {
                                    position: absolute;
                                    top: 10px;
                                    right: 6px;
                                    cursor: pointer;
                                }

                                & > input,
                                & > select {
                                    width: 100%;
                                    height: 35px;
                                    padding: 5px 30px;
                                    background: var(--community-upcoming-scrimmage-bg-color);
                                    color: #ADADAD;
                                    width: 280px;
                                }
                            }
                            .form_action {
                                margin: 30px 0 0;
                                display: flex;
                                justify-content: space-between;
                                align-items: center;

                                &.content_center { 
                                    justify-content: center !important;
                                }

                            }

                            .form_action_text {
                                cursor: pointer;
                                font-weight: bold;
                                font-size: 11px;
                                line-height: 15px;
                                text-align: center;
                                text-decoration-line: underline;
                                color: #E64747;
                                display: inline-block;
                            }

                            .form_action_button {
                                font-weight: bold;
                                font-size: 11px;
                                line-height: 15px;
                                text-align: center;
                                padding: 5px 10px;
                                background: #EE733A;
                                border-radius: 1.5px;
                                cursor: pointer;
                            }
                        }
                    }

                    .content_list {
                        margin-top: 15px;

                        .user_stats_info {
                            margin-bottom: 5px;
                            padding: 8px;
                            display: flex;
                            align-items: center;
                            background: #1D1F2C;
                            border-radius: 3px;

                            .user_image {
                                width: 40px;
                                height: 40px;
                                margin-right: 10px;

                                img {
                                    width: 100%;
                                }
                            }

                            .user_stats {
                                margin-right: 10px;
                                width: 140px;

                                .user_name {
                                    font-weight: normal;
                                    font-size: 12px;
                                    line-height: 16px;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }

                                .user_rank {
                                    font-weight: normal;
                                    font-size: 10px;
                                    line-height: 14px;
                                    color: #ADADAD;
                                    display: flex;
                                    align-items: center;
                                    margin-top: 5px;

                                    img {
                                        margin-right: 5px;
                                        width: 11px;
                                        height: 11px;
                                    }
                                }
                            }

                            .user_action {
                                margin-right: 10px;

                                .action_add {
                                    width: 25px;
                                    height: 25px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    background: #E97B26;
                                    font-size: 23px;
                                    font-weight: bold;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }
            .hubs_popup_actions {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 40px;

                .hubs_popup_button {
                    margin: 0 5px;
                    padding: 8px 16px;
                    font-weight: bold;
                    font-size: 15px;
                    line-height: 20px;
                    text-align: center;
                    border-radius: 2px;
                    width: 180px;
                    cursor: pointer;
                    color: #FFFFFF;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &.btn_esc {
                        background: #69769B;
                    }

                    &.btn_save {
                        background: #EE733A;
                    }
                }
            }
        }
    }
}
.hubs_popup_wrapper::-webkit-scrollbar {
    display: none;
}
@supports (-webkit-touch-callout: none) {
    @media (max-width: 590px) {
        #hubs_popup_add_filter_upcoming_scrimmage.hubs_popup_wrapper, #hubs_popup_add_filter_manage_scrimmage.hubs_popup_wrapper {
            padding-top: 50px;
        }
    }
}
@media (max-width: 590px) {
    .hubs_popup_wrapper {
        .hubs_popup_container {
            min-width: 100%;
            width: 100%;
            transform: unset;

            .hubs_popup_header {
                -webkit-clip-path: unset;
                clip-path: unset;
            }
            .hubs_popup_body {
                .hubs_popup_contents {
                    zoom: 1;
                    
                    .hubs_popup_content {
                        .content_cards {
                            width: 100%;

                            & > div {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }

        .scrimmage_teams {
            flex-wrap: wrap;
            justify-content: center !important;
        }

        .scrimmage_team_card {
            width: 100%;
            margin: 10px 0;
        }

        .last_step_info {
            flex-wrap: wrap;
            padding: 0 !important;
            border-left: unset;

            .scrimmage_info_title {
                width: 100%;
                height: 40px;
                background: #4C4E5B;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                border-right: unset;
            }

            .scrimmage_start_time, .scrimmage_game, .scrimmage_duration {
                width: 100%;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
            }
            .scrimmage_start_time::after, .scrimmage_game::after {
                height: 0;
                width: 0;
            }
        }

        .confirm_scrimmage_btn {
            width: 100% !important;
        }

        .scrimmage_select_team {
            padding: 40px 0 !important;
        }
    }
}
.game_edit_section {
    .multiselect__content-wrapper, .game_account_scrollbar {
        &::-webkit-scrollbar {
          width: 6px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background-color: var(--header-modal-game-account-bg);
        }
        &::-webkit-scrollbar-track {
          border-radius: 4px;
          background-color: var(--content-modal-game-account-bg);
        }
    }

    .multiselect__tags {
        background: var(--dropdown-modal-game-account-bg);
        border-radius: 0;
        border: none;
    }

    .multiselect__single {
        background: var(--dropdown-modal-game-account-bg);
        color: var(--text-color);
    }
    .multiselect__content-wrapper {
        background: var(--dropdown-modal-game-account-bg);
        color: var(--text-color);
        border: none;
        border-radius: 0;
    }
    .multiselect__option {
        background: var(--dropdown-modal-game-account-bg);
        color: var(--text-color);

        &--selected {
            background: var(--color-dark-alt);
        }

        &--highlight {
            background-color: var(--color-border);
        }
    }
    .option__title {
        display: flex;
        align-items: center;
        font-size: 13px !important;

        img {
            max-width: 20px;
            height: auto;
            margin-right: 10px;
        }
    }
    .multiselect__content-wrapper {
        max-height: 200px !important;
        position: static !important;
        z-index: 100;
    }

    .multiselect__option--selected, .multiselect__option--selected.multiselect__option--highlight {
        background: #CECECE;
        color: #000000;
    }
}